* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  ::-webkit-scrollbar {
    width: 0.35rem;
    background: #cfd9eabd;
  }

  ::-webkit-scrollbar-thumb {
    width: 0.35rem;
    background: var(--color-secondary);
  }
}

body {
  font-family: 'Raleway', sans-serif;
}